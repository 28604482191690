import {useState} from "react";
import {host} from '../constants';

export const useSerialGenres = () => {
    return useProvideSerialGenres();
};

function useProvideSerialGenres() {
    const [serialGenres, setSerialGenres] = useState(null);

    const loadSerialGenres = async (token, serialId) => {
        const serialGenres = await loadAllByHttp(token, serialId);
        setSerialGenres(serialGenres);
        return serialGenres;
    }

    const createGenre = async (token, serialId, genreId) => {
        const created = await createByHttp(token, serialId, genreId);
        if (serialGenres)
            setSerialGenres([created, ...serialGenres]);
        return created;
    }

    const deleteGenre = async (token, serialId, genreId) => {
        const deleted = await deleteByHttp(token, serialId, genreId);
        if (serialGenres) {
            const index = serialGenres.findIndex(g => g.genreId === deleted.genreId);
            const temp = [...serialGenres];
            if (index > -1)
                temp.splice(index, 1);

            setSerialGenres(temp);
        }

        return deleted;
    }

    return {
        serialGenres,
        loadSerialGenres,
        createGenre,
        deleteGenre
    };
}

async function loadAllByHttp(token, serialId) {
    const url = host + `/api/serial/${serialId}/genre`;

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    const data = await response.json();
    console.log('SerialGenres loaded ', data);
    return data;
}

async function createByHttp(token, serialId, genreId) {
    const url = host + `/api/serial/${serialId}/genre/${genreId}`;

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    const data = await response.json();
    console.log('SerialGenre created ', data);
    return data;
}

async function deleteByHttp(token, serialId, genreId) {
    const url = host + `/api/serial/${serialId}/genre/${genreId}`;

    const response = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    const data = await response.json();
    console.log('SerialGenre deleted ', data);
    return data;
}