import MemberList from "./MemberList";
import GenreList from "./GerneList";
import EpisodeList from "./EpisodeList";
import Poster from "./Poster";
import {useState} from "react";

function Serial(params) {
    const serial = params.serial;
    const name = `${serial.name} / ${serial.nameOriginal} | ${serial.season}`;
    const [showEpisodes, setShowEpisodes] = useState(false);
    const user = params.user;

    return (
        <div style={{borderBottom: '2px dashed', paddingBottom: '10px', paddingTop: '10px'}}>
            <button  onClick={() => params.onEditClicked(serial)}>Змінити</button>
            <a style={{paddingLeft: '5px'}} href={serial.downloadUrl}>
                <b>Скачати тут</b>
            </a>
            <br/>
            <a>
                <b>{name}</b>
            </a>
            <br/>
            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', justifyItems: 'center'}}>
                <Poster name={'Постер'} link={serial.posterUrl}></Poster>
                <Poster name={'Маленький постер'} link={serial.placeholderPosterUrl}></Poster>
            </div>
            <br/>
            <div>
                <b>жанри:</b> <GenreList genres={serial.genreSerials.map(t => t.genre.name)}></GenreList>
            </div>
            <a>
                <b>рік:</b> {serial.year}
            </a>
            <p>
                <i> {serial.description}</i>
            </p>
            <div>
                <b>переклад:</b>
                <MemberList members={serial.translatorSerials.map(t => t.member)}></MemberList>
            </div>
            <br/>
            <div>
                <b>озвучували:</b>
                <MemberList members={serial.voiceActorSerials.map(t => t.member)}></MemberList>
            </div>
            <br/>
            {!showEpisodes ?
                <button onClick={() => setShowEpisodes(true)}>Показати серії</button>
                :
                <div>
                    <b>серії:</b>
                    <EpisodeList user={user} serialId={serial.id}></EpisodeList>
                </div>
            }
        </div>
    );
}

export default Serial;