import React, {useState} from "react";
import {useAuth} from "./hooks/UserAuth.js"

export function LoginForm() {
    const auth = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    function validateForm() {
        return email.length > 0 && password.length > 0;
    }

    async function handleSubmit() {
        await auth.signin(email, password);
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10px'}}>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit();
                }}>
                <label htmlFor="login">Login:</label><br/>
                <input type="text"
                       id="login"
                       name="login"
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}></input>
                <br/>
                <label htmlFor="password">Password:</label>
                <br/>
                <input type="password" 
                       id="password" 
                       name="password"
                       value={password}
                       onChange={(e) => setPassword(e.target.value)}></input>
                <br/>
                <input type="submit" value="Login" disabled={!validateForm()}></input>
            </form>
        </div>
    );
}