import {useEffect} from "react";
import {EmptyMember} from "./EmptyMember";
import {Member} from "./Member";
import {useNavigate} from "react-router-dom";
import {useMembers} from "../../hooks/UseMembers";

export function MembersPanel(props) {
    const user = props.user;
    const membersHook = useMembers();
    const members = membersHook.members;

    const navigate = useNavigate();

    const onBackClicked = () => {
        navigate('/');
    }

    const onCreateClicked = async (genre) => {
        await membersHook.createMember(user.token, genre);
    }
    const onDeleteClicked = async (genre) => {
        await membersHook.deleteMember(user.token, genre);
    }

    useEffect(() => {
        membersHook.loadMembers(user.token);
    }, [user]);

    const memberElements = members?.map(m => <Member member={m} key={m.id} onDeleteClicked={onDeleteClicked}/>);
    return (
        members ?
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: '10px', paddingLeft: '15px', paddingRight: '15px'}}>
                <button onClick={onBackClicked}>Повернутися</button>
                <EmptyMember onCreateClicked={onCreateClicked}/>
                <div>
                    {memberElements}
                </div>
            </div>
            :
            <div>loading</div>
    );
}