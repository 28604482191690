import {useForm} from "react-hook-form";
import {useEffect} from "react";

export function EditEpisode(props) {
    const episode = props.episode;
    const {register, handleSubmit, watch, reset} = useForm({
        defaultValues: episode
    });

    useEffect(() => {
        reset(episode);
    }, [episode]);

    function onSubmit(data) {
        props.onSaveClicked(data);
    }

    return (
        <div style={{paddingTop: '10px', width: '100%'}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input style={{width: '35px',}} {...register('serialOrder')} type="number" name="serialOrder"/>
                <input style={{width: '40%',}} {...register('name')} type="text" name="name"/>
                <input style={{width: '40%'}} {...register('url')} type="link" name="url"/>
                <input type="submit" value="зберегти"/>
                <button onClick={(event) => {
                    event.preventDefault();
                    props.onDeleteClicked(episode)
                }}>видалити</button>
            </form>
        </div>

    );
}
