import {useForm} from "react-hook-form";
import Poster from "../display/serials/Poster";
import {useEffect} from "react";

export function EditSerial(props) {
    const serial = props.serial ?? {
        name: `назва аніме`,
        nameOriginal: `оригінальне названня аніме`,
        season: 'сезон / для фільму байдуже',
        year: 2022,
        description: 'опис',
        posterUrl: '',
        placeholderPosterUrl: '',
        downloadUrl: 'https://toloka.to/t'
    };

    const {register, handleSubmit, watch, reset} = useForm({
        defaultValues: serial
    });

    useEffect(() => {
        reset(serial);
    }, [props.serial]);

    function onSubmit(data) {
        props.onSaveClicked(data);
    }

    return (
        <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
            <form style={{display: 'grid', gridTemplateColumns: '1fr', justifyItems: 'center', paddingTop: '10px'}} onSubmit={handleSubmit(onSubmit)}>
                <a>тип</a>
                <select key='type' {...register('type')} name="type">
                    <option value="1">Багатосерійка</option>
                    <option value="2">Фільм</option>
                </select>
                <a>назва</a>
                <input style={{width: '100%',}} {...register('name')} type="text" name="name"/>
                <a>оригінальна назва</a>
                <input style={{width: '100%'}} {...register('nameOriginal')} type="text" name="nameOriginal"/>
                <a>сезон</a>
                <input style={{width: '100%'}} {...register('season')} type="text" name="season"/>
                <a>рік</a>
                <input style={{width: '100%'}} {...register('year')} type="number" name="year"/>
                <a>опис</a>
                <textarea style={{width: '100%', minHeight: '100px',}} {...register('description')} type="text" name="description"/>
                <a>лінк на скачування</a>
                <input style={{width: '100%'}} {...register('downloadUrl')} type="link" name="downloadUrl"/>
                <a>постер</a>
                <input style={{width: '100%'}} {...register('posterUrl')} type="link" name="posterUrl"/>
                <Poster link={watch('posterUrl')}></Poster>
                <a>маленький постер</a>
                <input style={{width: '100%'}} {...register('placeholderPosterUrl')} type="link" name="placeholderPosterUrl"/>
                <Poster link={watch('placeholderPosterUrl')}></Poster>
                <br/>
                <input type="submit" value="зберегти"/>
            </form>
            {serial.id && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10px'}}>
                <button onClick={() => props.onDeleteClicked(serial)}>Видалити</button>
            </div>}
        </div>
    );
}