function Episode(params) {
    const episode = params.episode;
    const link = episode.url.includes("t.me") ? "telegram" : "other";
    const color = episode.url.includes("t.me") ? "green" : "red";

    return (
        <div>
            <i>{episode.name}</i> | <b>посилання: </b> <a style={{color: color}} href={episode.url}><i>{link}</i></a>
        </div>
    );
}

export default Episode;