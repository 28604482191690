import {useForm} from "react-hook-form";
import {useEffect} from "react";

export function EmptySerialGenres(props) {
    const genres = props.genres;
    const {register, handleSubmit, reset} = useForm({
        defaultValues: {genreId: genres[0].id}
    });

    useEffect(() => {
        reset({genreId: genres[0].id});
    }, [genres]);
    
    if (!genres)
        return (<div>Завантаження...</div>);

    function onSubmit(data) {
        props.onSaveClicked(data);
    }

    const options = genres.map(genre => <option key={genre.id} value={genre.id}>{genre.name}</option>);

    return (
        <div style={{paddingTop: '5px'}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <select key='type' {...register('genreId')} name="genreId">
                    {options}
                </select>
                <input type="submit" value="Додати"/>
            </form>
        </div>
    );
}