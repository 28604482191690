import Serial from "./Serial";
import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from 'react-router-dom';
import PageButtons from "./PageButtons";
import {useSerials} from "../../hooks/UseSerials";
import {useForm} from "react-hook-form";

function SerialList(params) {
    const pageSize = 15;
    const user = params.user;
    const [searchParams, setSearchParams] = useSearchParams();

    const useSerialsHook = useSerials();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(Number.parseInt(searchParams.get('page') ?? 1));
    const {register, handleSubmit, watch, reset} = useForm();
    const [pageSearch, setPageSearch] = useState(searchParams.get('filter') ?? '');

    const generatePath = (page, filter) => {
        let path = `/serials?page=${page}`;

        if (pageSearch)
            path += `&filter=${filter}`;
        return path;
    }
    const onButtonClicked = (index) => {
        if (pageSearch)
            setSearchParams({page: index, filter: pageSearch});
        else
            setSearchParams({page: index});
        setCurrentPage(index);
    }

    const onEditClicked = (serial) => {
        navigate(`/serials/edit?id=${serial.id}`, {replace: true});
    }

    function OnSearchClicked(data) {
        if(data.search)
            setSearchParams({page: currentPage, filter: data.search});
        else
            setSearchParams({page: currentPage});

        setPageSearch(data.search);
    }

    useEffect(() => {
        useSerialsHook.loadCount(user.token, pageSearch);
    }, [user, pageSearch]);

    useEffect(() => {
        useSerialsHook.loadAll(user.token, pageSize, ((currentPage ?? 1) - 1) * pageSize, pageSearch);
        reset({search: pageSearch});

    }, [user, currentPage, pageSearch]);

    if (!useSerialsHook.count || useSerialsHook.count === 0)
        return (<div>Loading...</div>);

    const totalPages = Math.ceil(useSerialsHook.count / pageSize);

    if (totalPages < currentPage)
        return (<div>Error</div>);

    if (!useSerialsHook.serials)
        return (<div>Loading...</div>);

    const serialsElements = useSerialsHook.serials.map(m => <Serial user={user} serial={m} onEditClicked={onEditClicked} key={m.id}/>);

    return (
        <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
            <form onSubmit={handleSubmit(OnSearchClicked)}>
                <input style={{width: '85%',}} {...register('search')} type="text" name="search"/>
                <input type="submit" value="пошук"/>
            </form>
            <div className='serials' style={{paddingBottom: '10px'}}>
                {serialsElements}
            </div>
            <PageButtons totalPages={totalPages} currentPage={currentPage} onButtonClicked={onButtonClicked}/>
        </div>
    );
}

export default SerialList;