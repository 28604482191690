import {useState} from "react";
import {host} from '../constants';

export const useGenres = () => {
    return useProvideGenres();
};

function useProvideGenres() {
    const [genres, setGenres] = useState(null);

    const loadGenres = async (token, serialId) => {
        const genres = await loadAllByHttp(token, serialId);
        setGenres(genres);
        return genres;
    }

    const createGenre = async (token, genre) => {
        const created = await createByHttp(token, genre);
        if (genres)
            setGenres([created, ...genres]);
        return created;
    }

    const deleteGenre = async (token, genre) => {
        const deleted = await deleteByHttp(token, genre);
        if (genres) {
            const index = genres.findIndex(g => g.id === deleted.id);
            const temp = [...genres];
            if (index > -1)
                temp.splice(index, 1);

            setGenres(temp);
        }

        return deleted;
    }

    return {
        genres,
        loadGenres,
        createGenre,
        deleteGenre
    };
}

async function loadAllByHttp(token) {
    const url = host + `/api/genre`;

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    const data = await response.json();
    console.log('Genres loaded ', data);
    return data;
}

async function createByHttp(token, genre) {
    const url = host + `/api/Genre/`;

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(genre)
    });

    const data = await response.json();
    console.log('Genre created ', data);
    return data;
}

async function deleteByHttp(token, genre) {
    const url = host + `/api/Genre/`;

    const response = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(genre)
    });

    const data = await response.json();
    console.log('Genre deleted ', data);
    return data;
}