import React, {useState, useContext, createContext} from 'react';
import {host} from "../constants";
import Cookies from 'js-cookie'

const cookieName = 'user';
const authContext = createContext();

const cookies = Cookies.withConverter({
    read: function (value, name) {
        console.log('read from cookie ' + name, value);
        return JSON.parse(value);
    },

    write: function (value, name) {
        return JSON.stringify(value);
    }
});

export function ProvideAuth({children}) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth() {
    const [user, setUser] = useState(cookies.get(cookieName));

    const signin = async (login, password) => {
        const user = await signinWithCookie(login, password);
        setUser(user);
        return user;
    }

    return {
        user,
        signin
    };
}

async function signinWithCookie(login, password) {
    const user = await signin(login, password);
    const expires = new Date();
    expires.setTime(expires.getTime() + (user.expiration * 1000));

    cookies.set(cookieName, user, {path: '/', expires: expires});

    return user;
}

async function signin(login, password) {
    const url = host + '/api/user/login'
    const input = {
        login: login,
        password: password
    };

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(input),
    });

    console.log('login request', JSON.stringify(input));
    console.log('login response', response);

    const data = await response.json();
    console.log('login data ', data);
    return data;
}