import Member from "./Member";

function MemberList(params) {
    const members = params.members;
    const membersElements = members.map(m => {
        return (
            <Member member={m} key={m.id}/>
        );
    });

    return (
        <div>
            {membersElements}
        </div>
    );
}

export default MemberList;