import React from "react";
import {useForm} from "react-hook-form";

export function EmptyGenre(props) {
    const {register, handleSubmit, setValue} = useForm();

    setValue('name', 'name');

    const onSubmit = (data) => {
        const genre = {
            name: data.name
        };

        if (props.onCreateClicked)
            props.onCreateClicked(genre);
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input {...register('name')} type="text" name="name"/>
                <input type="submit" value="Create"/>
            </form>
        </div>
    );
}