import {useForm} from "react-hook-form";
import {useEffect} from "react";

export function EmptyEpisodes(props) {
    const {register, handleSubmit, reset} = useForm({
        defaultValues: props.episode
    });

    useEffect(() => {
        reset(props.episode);
    }, [props.episode]);

    function onSubmit(data) {
        props.onSaveClicked(data);
    }

    return (
        <div style={{paddingTop: '10px', width: '100%'}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input style={{width: '35px',}} {...register('serialOrder')} type="number" name="serialOrder"/>
                <input style={{width: '40%',}} {...register('name')} type="text" name="name"/>
                <input style={{width: '40%'}} {...register('url')} type="link" name="url"/>
                <input type="submit" value="Додати"/>
            </form>
        </div>
    );
};

