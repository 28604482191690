import {useState} from "react";
import {host} from '../constants';

export const useSerialTranslators = () => {
    return useProvideSerialTranslators();
};

function useProvideSerialTranslators() {
    const [serialTranslators, setSerialTranslators] = useState(null);

    const loadSerialTranslators = async (token, serialId) => {
        const serialTranslators = await loadAllByHttp(token, serialId);
        setSerialTranslators(serialTranslators);
        return serialTranslators;
    }

    const createSerialTranslator = async (token, serialId, memberId) => {
        const created = await createByHttp(token, serialId, memberId);
        if (serialTranslators)
            setSerialTranslators([created, ...serialTranslators]);
        return created;
    }

    const deleteSerialTranslator = async (token, serialId, memberId) => {
        const deleted = await deleteByHttp(token, serialId, memberId);
        if (serialTranslators) {
            const index = serialTranslators.findIndex(g => g.memberId === deleted.memberId);
            const temp = [...serialTranslators];
            if (index > -1)
                temp.splice(index, 1);

            setSerialTranslators(temp);
        }

        return deleted;
    }

    return {
        serialTranslators,
        loadSerialTranslators,
        createSerialTranslator,
        deleteSerialTranslator
    };
}

async function loadAllByHttp(token, serialId) {
    const url = host + `/api/serial/${serialId}/translator`;

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    const data = await response.json();
    console.log('SerialTranslators loaded ', data);
    return data;
}

async function createByHttp(token, serialId, memberId) {
    const url = host + `/api/serial/${serialId}/translator/${memberId}`;

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    const data = await response.json();
    console.log('SerialTranslator created ', data);
    return data;
}

async function deleteByHttp(token, serialId, memberId) {
    const url = host + `/api/serial/${serialId}/translator/${memberId}`;

    const response = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    const data = await response.json();
    console.log('SerialTranslator deleted ', data);
    return data;
}