import {useEffect} from "react";
import {useMembers} from "../hooks/UseMembers";
import {EditMember} from "./EditMember";
import {EmptyMember} from "./EmptyMember";
import {useSerialVoiceActors} from "../hooks/UseSerialVoiceActors";

export function EditSerialVoiceActors(props) {
    const user = props.user;
    const serialId = props.serialId;
    const membersHook = useMembers();
    const serialVoiceActorsHook = useSerialVoiceActors();

    useEffect(() => {
        membersHook.loadMembers(user.token);
    }, [user]);

    useEffect(() => {
        if (serialId)
            serialVoiceActorsHook.loadSerialVoiceActors(user.token, serialId);
    }, [user, serialId]);

    const onSaveClicked = async (data) => {
        await serialVoiceActorsHook.createSerialVoiceActor(user.token, serialId, data.memberId);
    };

    const onDeleteClicked = async (member) => {
        await serialVoiceActorsHook.deleteSerialVoiceActor(user.token, serialId, member.id);
    }

    if (!membersHook.members || !serialVoiceActorsHook.serialVoiceActors)
        return (<div>Loading...</div>);

    const availableMembers = membersHook.members.filter(member => !serialVoiceActorsHook.serialVoiceActors.some(st => st.memberId === member.id));
    const memberElements = serialVoiceActorsHook.serialVoiceActors.map(st => <EditMember member={st.member} key={st.memberId} onDeleteClicked={onDeleteClicked}/>);
    return (
        <div style={{display: 'grid', gridTemplateColumns: '1fr', justifyItems: 'center', paddingTop: '10px'}}>
            <a>Актори озвучки</a>
            <EmptyMember members={availableMembers} onSaveClicked={onSaveClicked}/>
            {memberElements}
        </div>
    );
}
