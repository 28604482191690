import {useState} from "react";
import {host} from '../constants';

export const useSerialVoiceActors = () => {
    return useProvideSerialVoiceActors();
};

function useProvideSerialVoiceActors() {
    const [serialVoiceActors, setSerialVoiceActors] = useState(null);

    const loadSerialVoiceActors = async (token, serialId) => {
        const serialVoiceActors = await loadAllByHttp(token, serialId);
        setSerialVoiceActors(serialVoiceActors);
        return serialVoiceActors;
    }

    const createSerialVoiceActor = async (token, serialId, memberId) => {
        const created = await createByHttp(token, serialId, memberId);
        if (serialVoiceActors)
            setSerialVoiceActors([created, ...serialVoiceActors]);
        return created;
    }

    const deleteSerialVoiceActor = async (token, serialId, memberId) => {
        const deleted = await deleteByHttp(token, serialId, memberId);
        if (serialVoiceActors) {
            const index = serialVoiceActors.findIndex(g => g.memberId === deleted.memberId);
            const temp = [...serialVoiceActors];
            if (index > -1)
                temp.splice(index, 1);

            setSerialVoiceActors(temp);
        }

        return deleted;
    }

    return {
        serialVoiceActors,
        loadSerialVoiceActors,
        createSerialVoiceActor,
        deleteSerialVoiceActor
    };
}

async function loadAllByHttp(token, serialId) {
    const url = host + `/api/serial/${serialId}/voiceActors`;

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    const data = await response.json();
    console.log('SerialVoiceActors loaded ', data);
    return data;
}

async function createByHttp(token, serialId, memberId) {
    const url = host + `/api/serial/${serialId}/voiceActors/${memberId}`;

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    const data = await response.json();
    console.log('SerialVoiceActor created ', data);
    return data;
}

async function deleteByHttp(token, serialId, memberId) {
    const url = host + `/api/serial/${serialId}/voiceActors/${memberId}`;

    const response = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    const data = await response.json();
    console.log('SerialVoiceActor deleted ', data);
    return data;
}