import {useEffect} from "react";
import {useMembers} from "../hooks/UseMembers";
import {useSerialTranslators} from "../hooks/UseSerialTranslators";
import {EditMember} from "./EditMember";
import {EmptyMember} from "./EmptyMember";

export function EditSerialTranslators(props) {
    const user = props.user;
    const serialId = props.serialId;
    const membersHook = useMembers();
    const serialTranslatorsHook = useSerialTranslators();

    useEffect(() => {
        membersHook.loadMembers(user.token);
    }, [user]);

    useEffect(() => {
        if (serialId)
            serialTranslatorsHook.loadSerialTranslators(user.token, serialId);
    }, [user, serialId]);

    const onSaveClicked = async (data) => {
        await serialTranslatorsHook.createSerialTranslator(user.token, serialId, data.memberId);
    };

    const onDeleteClicked = async (member) => {
        await serialTranslatorsHook.deleteSerialTranslator(user.token, serialId, member.id);
    }

    if (!membersHook.members || !serialTranslatorsHook.serialTranslators)
        return (<div>Loading...</div>);

    const availableMembers = membersHook.members.filter(member => !serialTranslatorsHook.serialTranslators.some(st => st.memberId === member.id));
    const memberElements = serialTranslatorsHook.serialTranslators.map(st => <EditMember member={st.member} key={st.memberId} onDeleteClicked={onDeleteClicked}/>);
    return (
        <div style={{display: 'grid', gridTemplateColumns: '1fr', justifyItems: 'center', paddingTop: '10px'}}>
            <a>Перекладачі</a>
            <EmptyMember members={availableMembers} onSaveClicked={onSaveClicked}/>
            {memberElements}
        </div>
    );
}
