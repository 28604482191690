
function GenreList(params) {
    const genres = params.genres;
   
    return (
        <a>
            {genres.join(', ')}
        </a>
    );
}

export default GenreList;