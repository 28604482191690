import {ProvideAuth} from "./hooks/UserAuth";
import {UserApp} from "./UserApp";

function App() {
    return (
        <ProvideAuth>
            <UserApp/>
        </ProvideAuth>
    );
}

export default App;
