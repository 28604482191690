import {useState} from "react";
import {host} from '../constants';

export const useEpisodes = () => {
    return useProvideEpisodes();
};

function useProvideEpisodes() {
    const [episodes, setEpisodes] = useState(null);

    const loadEpisodes = async (token, serialId) => {
        const episodes = await loadAllByHttp(token, serialId);
        setEpisodes(episodes);
        return episodes;
    }

    const createEpisode = async (token, serialId, episode) => {
        const created = await createByHttp(token, serialId, episode);
        if (episodes)
            setEpisodes([...episodes, created]);

        return created;
    }

    const updateEpisode = async (token, serialId, episode) => {
        const updated = await updateByHttp(token, serialId, episode);
        if (episodes) {
            const index = episodes.findIndex(g => g.id === updated.id);
            const temp = [...episodes];
            temp[index] = updated;
            setEpisodes(temp);
        }

        return updated;
    }

    const deleteEpisode = async (token, serialId, episode) => {
        const deleted = await deleteByHttp(token, serialId, episode);
        if (episodes) {
            const index = episodes.findIndex(g => g.id === deleted.id);
            const temp = [...episodes];
            if (index > -1)
                temp.splice(index, 1);

            setEpisodes(temp);
        }

        return deleted;
    }

    return {
        episodes,
        loadEpisodes,
        createEpisode,
        updateEpisode,
        deleteEpisode

    };
}

async function loadAllByHttp(token, serialId) {
    const url = host + `/api/episode/${serialId}`;

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    const data = await response.json();
    console.log('episodes loaded ', data);
    return data;
}

async function createByHttp(token, serialId, episode) {
    const url = host + `/api/episode/${serialId}`;

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(episode)
    });

    const data = await response.json();
    console.log('episode created ', data);
    return data;
}

async function updateByHttp(token, serialId, episode) {
    const url = host + `/api/episode/${serialId}`;

    const response = await fetch(url, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(episode)
    });

    const data = await response.json();
    console.log('episode updated ', data);
    return data;
}

async function deleteByHttp(token, serialId, episode) {
    const url = host + `/api/episode/${serialId}`;

    const response = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(episode)
    });

    const data = await response.json();
    console.log('episode deleted ', data);
    return data;
}