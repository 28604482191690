import {useState} from "react";
import {host} from '../constants';

export const useSerials = () => {
    return useProvideSerials();
};

function useProvideSerials() {
    const [serials, setSerials] = useState(null);
    const [count, setCount] = useState(0);
    const [serial, setSerial] = useState(null);

    const loadAll = async (token, take, skip, filter) => {
        setSerials(null);
        const serials = await loadAllByHttp(token, take, skip, filter);
        setSerials(serials);
        return serials;
    }

    const loadCount = async (token, filter) => {
        const loaded = await loadCountByHttp(token, filter);
        setCount(loaded);
        return loaded;
    }

    const getById = async (token, serialId) => {
        const loaded = await loadByIdByHttp(token, serialId);
        setSerial(loaded);
        return loaded;
    }

    const create = async (token, serial) => {
        const created = await createByHttp(token, serial);
        return created;
    }

    const update = async (token, serial) => {
        const updated = await updateByHttp(token, serial);
        return updated;
    }

    const deleteSerial = async (token, serial) => {
        const deleted = await deleteByHttp(token, serial);
        return deleted;
    }

    return {
        serials,
        count,
        serial,
        loadCount,
        loadAll,
        getById,
        create,
        update,
        deleteSerial
    };
}

async function loadAllByHttp(token, take, skip, filter) {
    let url = host + `/api/serial?take=${take}&skip=${skip}`;

    if (filter)
        url += `&filter=${filter}`;

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    const data = await response.json();
    console.log('serials loaded ', data);
    return data;
}

async function loadCountByHttp(token, filter) {
    let url = host + `/api/serial/count`;
    if (filter)
        url += `?filter=${filter}`;

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    const data = await response.json();
    console.log('serials loaded count', data);
    return data;
}

async function loadByIdByHttp(token, serialId) {
    const url = host + `/api/serial/${serialId}`;

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    const data = await response.json();
    console.log('serial loaded by id', data);
    return data;
}

async function createByHttp(token, serial) {
    const url = host + '/api/serial';

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(serial)
    });

    const data = await response.json();
    console.log('serial created ', data);
    return data;
}

async function updateByHttp(token, serial) {
    const url = host + '/api/serial';

    const response = await fetch(url, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(serial)
    });

    const data = await response.json();
    console.log('serial updated ', data);
    return data;
}

async function deleteByHttp(token, serial) {
    const url = host + '/api/serial';

    const response = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(serial)
    });

    const data = await response.json();
    console.log('serial deleted ', data);
    return data;
}