import {useEffect} from "react";
import {useGenres} from "../../hooks/UseGenres";
import {EmptyGenre} from "./EmptyGenre";
import {Genre} from "./Genre";
import {useNavigate} from "react-router-dom";

export function GenresPanel(props) {
    const user = props.user;
    const genreHook = useGenres();
    const genres = genreHook.genres;

    const navigate = useNavigate();

    const onBackClicked = () => {
        navigate('/');
    }

    const onCreateClicked = async (genre) => {
        await genreHook.createGenre(user.token, genre);
    }
    const onDeleteClicked = async (genre) => {
        await genreHook.deleteGenre(user.token, genre);
    }

    useEffect(() => {
        genreHook.loadGenres(user.token);
    }, [user]);

    const genresElements = genres?.map(g => <Genre genre={g} key={g.id} onDeleteClicked={onDeleteClicked}/>);
    return (
        genres ?
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: '10px', paddingLeft: '15px', paddingRight: '15px'}}>
                <button onClick={onBackClicked}>Повернутися</button>
                <EmptyGenre onCreateClicked={onCreateClicked}/>
                <div>
                    {genresElements}
                </div>
            </div>
            :
            <div>loading</div>
    );
}