function PageButtons(params) {
    const totalPages = params.totalPages;
    const currentPage = params.currentPage;
    const onButtonClicked = params.onButtonClicked;

    const buttonsElements = GenerateButtons(totalPages, currentPage).map((b) =>
        <button style={{backgroundColor: b.selected ? '#4CAF50' : 'white', border: 'none', display: 'inline-block', padding: '10px 28px', fontSize: '16px'}}
                onClick={(e) => {
                    e.preventDefault();
                    onButtonClicked(b.index);
                }}
                key={b.index}>{b.name}</button>);

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {buttonsElements}
        </div>
    );
}

function GenerateButtons(totalPages, currentPage) {
    const ButtonCount = 5;
    const ButtonCenter = 3;
    const buttons = [];

    if (totalPages <= ButtonCount) {
        for (let i = 1; i <= totalPages; i++)
            buttons.push({name: i === currentPage ? `-${i}-` : `${i}`, index: i, selected: i === currentPage});

        return buttons;
    }

    if (currentPage <= ButtonCenter) {
        for (let i = 1; i <= ButtonCenter; i++)
            buttons.push({name: i === currentPage ? `-${i}-` : `${i}`, index: i, selected: i === currentPage});

        buttons.push({name: `${ButtonCenter + 1}>`, index: ButtonCenter + 1, selected: false});
        buttons.push({name: `${totalPages}>>`, index: totalPages, selected: false});
        return buttons;
    }

    if (currentPage >= totalPages - ButtonCenter + 1) {
        buttons.push({name: `<<${1}`, index: 1, selected: false});
        buttons.push({name: `<${totalPages - ButtonCenter}`, index: totalPages - ButtonCenter, selected: false});
        for (let i = totalPages - ButtonCenter + 1; i <= totalPages; i++)
            buttons.push({name: i === currentPage ? `-${i}-` : `${i}`, index: i, selected: i === currentPage});

        return buttons;
    }

    buttons.push({name: `<<${1}`, index: 1, selected: false});
    buttons.push({name: `<${currentPage - 1}`, index: currentPage - 1, selected: false});
    buttons.push({name: `-${currentPage}-`, index: currentPage, selected: true});
    buttons.push({name: `${currentPage + 1}>`, index: currentPage + 1, selected: false});
    buttons.push({name: `${totalPages}>>`, index: totalPages, selected: false});

    return buttons;
}

export default PageButtons;