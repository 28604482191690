import {useForm} from "react-hook-form";
import {useEffect} from "react";

export function EmptyMember(props) {
    const members = props.members;
    const {register, handleSubmit, reset} = useForm({
        defaultValues: {memberId: members[0].id}
    });

    useEffect(() => {
        reset({memberId: members[0].id});
    }, [members]);

    if (!members)
        return (<div>Завантаження...</div>);

    function onSubmit(data) {
        props.onSaveClicked(data);
    }

    const options = members.map(member => <option key={member.id} value={member.id}>{member.nickName}</option>);

    return (
        <div style={{paddingTop: '5px'}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <select key='type' {...register('memberId')} name="memberId">
                    {options}
                </select>
                <input type="submit" value="Додати"/>
            </form>
        </div>
    );
}