import {useNavigate} from 'react-router-dom';

function AdminPanel(params) {

    const navigate = useNavigate();

    const onAllSerialsClicked = () => {
        navigate('/serials?page=1');
    }

    const onCreateSerialsClicked = () => {
        navigate('/serials/edit');
    }

    const onAllGenresClicked = () => {
        navigate('/genres');
    }

    const onAllMembersClicked = () => {
        navigate('/members');
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10px'}}>
            <div style={{display: 'inline'}}>
                <button onClick={onAllSerialsClicked}>Всі аніме</button>
                <br/>
                <button onClick={onCreateSerialsClicked}>Створити аніме</button>
                <br/>
                <button onClick={onAllGenresClicked}>Всі жанри</button>
                <br/>
                <button onClick={onAllMembersClicked}>Всі members</button>
            </div>
        </div>
    );
}

export default AdminPanel;