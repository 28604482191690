import Episode from "./Episode";
import {useEffect} from "react";
import {useEpisodes} from "../../hooks/UseEpisodes";

function EpisodeList(params) {
    const user = params.user;
    const serialId = params.serialId;
    const episodesHook = useEpisodes();

    useEffect(() => {
        episodesHook.loadEpisodes(user.token, serialId);
    }, [serialId]);

    if (!episodesHook.episodes)
        return (<div>Loading...</div>);

    const episodesElements = episodesHook.episodes.map(m => {
        return (
            <Episode episode={m} key={m.id}/>
        );
    });

    return (
        <div>
            {episodesElements}
        </div>
    );
}

export default EpisodeList;