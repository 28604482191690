import {LazyLoadImage} from "react-lazy-load-image-component";

function Poster(params) {
    let link = params.link;
    const name = params.name;

    if (!link || link.length === 0)
        link = "https://via.placeholder.com/150x210";

    return (
        <div>
            {name && <a style={{color: link.includes("https://i.ibb.co/") ? "green" : "red"}}>{name}</a>}
            <br/>
            <LazyLoadImage effect="blur" src={link} width={150} height={210}/>
        </div>
    );
}

export default Poster;