import {EmptySerialGenres} from "./EmptySerialGenre";
import {useGenres} from "../hooks/UseGenres";
import {useEffect} from "react";
import {useSerialGenres} from "../hooks/UseSerialGenres";
import {EditSerialGenre} from "./EditSerialGenre";

export function EditSerialGenres(props) {
    const user = props.user;
    const serialId = props.serialId;
    const genresHook = useGenres();
    const serialGenresHook = useSerialGenres();

    useEffect(() => {
        genresHook.loadGenres(user.token);
    }, [user]);

    useEffect(() => {
        if (serialId)
            serialGenresHook.loadSerialGenres(user.token, serialId);
    }, [user, serialId]);

    const onSaveClicked = async (genre) => {
        await serialGenresHook.createGenre(user.token, serialId, genre.genreId);
    };

    const onDeleteClicked = async (serialGenre) => {
        await serialGenresHook.deleteGenre(user.token, serialId, serialGenre.genreId);
    }

    if (!genresHook.genres || !serialGenresHook.serialGenres)
        return (<div>Loading...</div>);

    const availableGenres = genresHook.genres.filter(genre => !serialGenresHook.serialGenres.some(serialGenre => serialGenre.genreId === genre.id));
    const serialGenreElements = serialGenresHook.serialGenres.map(serialGenre => <EditSerialGenre serialGenre={serialGenre} key={serialGenre.genreId} onDeleteClicked={onDeleteClicked}/>);
    return (
        <div style={{display: 'grid', gridTemplateColumns: '1fr', justifyItems: 'center', paddingTop: '10px'}}>
            <a>Жанри</a>
            <EmptySerialGenres genres={availableGenres} onSaveClicked={onSaveClicked}/>
            {serialGenreElements}
        </div>
    );
}
