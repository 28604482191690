import {useSearchParams, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {EditSerial} from "./EditSerial";
import {useSerials} from "../hooks/UseSerials";
import {EditEpisodes} from "./EditEpisodes";
import {EditSerialGenres} from "./EditSerialGenres";
import {EditSerialTranslators} from "./EditSerialTranslators";
import {EditSerialVoiceActors} from "./EditSerialVoiceActors";

export function EditSerialPanel(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [serialId, setSerialId] = useState(searchParams.get('id'));
    const navigate = useNavigate();
    const useSerialsHook = useSerials();
    const user = props.user;

    useEffect(() => {
        if (serialId)
            useSerialsHook.getById(user.token, serialId);
    }, [user, serialId]);

    const onBackClicked = () => {
        navigate('/');
    }

    const onSaveClicked = async (serial) => {
        if (serialId) {
            await useSerialsHook.update(user.token, serial);
            alert('Anime updated');
        } else {
            const newSerial = await useSerialsHook.create(user.token, serial);
            setSerialId(newSerial.id);
            navigate(`/serials/edit?id=${newSerial.id}`, {replace: true});
            alert('Anime created');
        }
    }

    const onDeleteClicked = async (serial) => {
        await useSerialsHook.deleteSerial(user.token, serial);
        navigate(`/`, {replace: true});
    }
    
    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10px'}}>
                <button onClick={onBackClicked}>Повернутися</button>
            </div>
            <EditSerial serial={useSerialsHook.serial} onSaveClicked={onSaveClicked} onDeleteClicked={onDeleteClicked}/>
            {serialId && <EditEpisodes user={user} serialId={useSerialsHook.serial?.id}/>}
            {serialId && <EditSerialGenres user={user} serialId={useSerialsHook.serial?.id}/>}
            {serialId && <EditSerialTranslators user={user} serialId={useSerialsHook.serial?.id}/>}
            {serialId && <EditSerialVoiceActors user={user} serialId={useSerialsHook.serial?.id}/>}
        </div>
    );
}