import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import AdminPanel from "./AdminPanel";
import {useAuth} from "./hooks/UserAuth";
import {LoginForm} from "./LoginForm";
import {GenresPanel} from "./display/genres/GenresPanel";
import SerialsPanel from "./display/serials/SerialsPanel";
import {MembersPanel} from "./display/members/MembersPanel";
import {EditSerialPanel} from "./create/EditSerialPanel";

export function UserApp(props) {
    const auth = useAuth();

    return (
        auth.user ?
            <Router>
                <Routes>
                    <Route path="/" element={<AdminPanel user={auth.user}/>}/>
                    <Route path="/serials" element={<SerialsPanel user={auth.user}/>}/>
                    <Route path="/serials/edit" element={<EditSerialPanel user={auth.user}/>}/>
                    <Route path="/genres" element={<GenresPanel user={auth.user}/>}/>
                    <Route path="/members" element={<MembersPanel user={auth.user}/>}/>
                </Routes>
            </Router>
            :
            <LoginForm/>
    );
}