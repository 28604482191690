function Member(params) {
    const member = params.member;

    return (
        <div>
            <i>{member.nickName}</i>
        </div>
    );
}

export default Member;