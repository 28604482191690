import {useNavigate} from 'react-router-dom';
import SerialList from "./SerialList";

function SerialsPanel(params) {
    const navigate = useNavigate();

    const onBackClicked = () => {
        navigate('/');
    }

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10px'}}>
                <button onClick={onBackClicked}>Повернутися</button>
            </div>
            <SerialList user={params.user}/>
        </div>
    );
}

export default SerialsPanel;