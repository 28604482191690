import {useEffect, useState} from "react";
import {EditEpisode} from "./EditEpisode";
import {useEpisodes} from "../hooks/UseEpisodes";
import {EmptyEpisodes} from "./EmptyEpisode";

export function EditEpisodes(props) {
    const user = props.user;
    const serialId = props.serialId;
    const episodesHook = useEpisodes();
    const [episode, setEpisode] = useState();

    useEffect(() => {
        if (serialId)
            episodesHook.loadEpisodes(user.token, serialId);
    }, [serialId]);

    useEffect(() => {
        if (!episodesHook.episodes)
            return;

        setEpisode({
            url: 'https://t.me/fanvoxua_video/',
            name: `Епізод ${episodesHook.episodes.length + 1} -`,
            serialOrder: episodesHook.episodes.length
        });
    }, [episodesHook.episodes?.length]);

    if (!episodesHook.episodes)
        return (<div>Loading...</div>);

    const onSaveClicked = async (episode) => {
        if (episode.id) {
            await episodesHook.updateEpisode(user.token, serialId, episode);
            alert('Епізод оновлено');
        } else {
            await episodesHook.createEpisode(user.token, serialId, episode);
            alert('Епізод додано');
        }
    }

    const onDeleteClicked = async (episode) => {
        await episodesHook.deleteEpisode(user.token, serialId, episode);
        alert('Епізод видалено');
    }

    const episodesElements = episodesHook.episodes.map(episode => <EditEpisode key={episode.id} episode={episode} onSaveClicked={onSaveClicked} onDeleteClicked={onDeleteClicked}/>);

    return (
        <div style={{paddingLeft: '15px', paddingRight: '15px', display: 'grid', gridTemplateColumns: '1fr', justifyItems: 'center', paddingTop: '10px'}}>
            <a>Епізоди</a>
            <EmptyEpisodes key={'empty-episode'} episode={episode} onSaveClicked={onSaveClicked}/>
            {episodesElements}
        </div>
    );
}