import {useState} from "react";
import {host} from '../constants';

export const useMembers = () => {
    return useProvideMembers();
};

function useProvideMembers() {
    const [members, setMembers] = useState(null);

    const loadMembers = async (token, serialId) => {
        const members = await loadAllByHttp(token, serialId);
        setMembers(members);
        return members;
    }

    const createMember = async (token, member) => {
        const created = await createByHttp(token, member);
        setMembers([created, ...members]);
        return created;
    }

    const deleteMember = async (token, member) => {
        const deleted = await deleteByHttp(token, member);
        const index = members.findIndex(g => g.id === deleted.id);
        const temp = [...members];
        if (index > -1)
            temp.splice(index, 1);

        setMembers(temp);
        return deleted;
    }

    return {
        members,
        loadMembers,
        createMember,
        deleteMember
    };
}

async function loadAllByHttp(token) {
    const url = host + `/api/member`;

    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    });

    const data = await response.json();
    console.log('Members loaded ', data);
    return data;
}

async function createByHttp(token, member) {
    const url = host + `/api/member/`;

    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(member)
    });

    const data = await response.json();
    console.log('Member created ', data);
    return data;
}

async function deleteByHttp(token, member) {
    const url = host + `/api/member/`;

    const response = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(member)
    });

    const data = await response.json();
    console.log('Member deleted ', data);
    return data;
}